<template>
  <div>
    <div class="row">
      <div class="col-md-8 offset-md-2" id="whole">
        <div class="card">
          <div class="card-header">
            <h5>My request details</h5>

            <span
              :class="getLeaveDetails.status | leaveStatusClass"
              class="badge mx-5"
              >{{ getLeaveDetails.status | leaveStatus }}</span
            >
              <div class="leave-is-paid" v-if="getLeaveDetails.status=='1'" >
                       <span class="badge badge-danger" v-if="getLeaveDetails.is_paid=='0'"> Unpaid </span>
                           <span class="badge badge-success" v-else> Paid </span>
                    </div>
            <div class="close">
              <router-link :to="{ name: 'leave.employee' }">
                <i class="fa fa-times"></i>
              </router-link>
            </div>
          </div>
          <div class="card-body">
            <!-- {{ getLeaveDetails }} -->

            <div class="row">
              <div class="col-md-8 offset-md-2" id="d1">
                <div class="card">
                  <div class="card-header">
                    <div class="leave-type">
                      <span
                        class="icon-circle"
                        :style="
                          'background-color:' +
                          getLeaveDetails.leave_type.color_code
                        "
                      >
                        <i :class="getLeaveDetails.leave_type.icon"></i
                      ></span>
                      <h5>{{ getLeaveDetails.leave_type.name }}</h5>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="flex__row flex__row--center shrink-0">
                      <div class="flex__col">
                        <a
                          class="calendar-card calendar-card--lg panel text-decoration-none"
                          ><div class="panel__header panel--highlighted">
                            <span class="calendar-card__day-week">{{
                              getLeaveDetails.from_day_name
                            }}</span>
                          </div>
                          <div class="panel__body calendar-card__content">
                            <div class="calendar-card__day-month">
                              {{ getLeaveDetails.from_day }}
                            </div>
                            <div class="calendar-card__date">
                              {{ getLeaveDetails.from_month_year }}
                            </div>
                          </div></a
                        >
                      </div>
                      <div class="flex__col flex__col--flex">
                        <svg
                          class="icon text-gray-light mx-1 icon-md"
                          viewBox="0 0 24 24"
                        >
                          <g><path d="M7 11h10v2H7z"></path></g>
                        </svg>
                      </div>
                      <div class="flex__col">
                        <a
                          class="calendar-card calendar-card--lg panel text-decoration-none"
                          ><div class="panel__header panel--highlighted">
                            <span class="calendar-card__day-week">{{
                              getLeaveDetails.to_day_name
                            }}</span>
                          </div>
                          <div class="panel__body calendar-card__content">
                            <div class="calendar-card__day-month">
                              {{ getLeaveDetails.to_day }}
                            </div>
                            <div class="calendar-card__date">
                              {{ getLeaveDetails.to_month_year }}
                            </div>
                          </div></a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <span class="text-center">
                      This request equals to
                      <b>
                        {{ getLeaveDetails.total_day }}
                        {{ getLeaveDetails.total_day > 1 ? "days" : "day" }}
                      </b>
                    </span>
                  </div>
                </div>

                <div class="note">
                  <div class="svg-icon">
                    <svg
                      class="icon icon-sm icon-info"
                      viewBox="0 0 24 24"
                      style="fill: #38b0f9"
                    >
                      <g>
                        <path
                          d="M12 22C6.486 22 2 17.514 2 12S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10zm0-18c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8z"
                        ></path>
                        <path
                          d="M12.335 12.67a.744.744 0 01-.67.001L7 10.338V14a2 2 0 002 2h6a2 2 0 002-2v-3.66l-4.665 2.33z"
                        ></path>
                        <path
                          d="M12 11.162l4.629-2.315A1.99 1.99 0 0015 8H9c-.674 0-1.267.335-1.629.846L12 11.162z"
                        ></path>
                      </g>
                    </svg>
                  </div>
                  <small class="ellipsis identifier__secondary text-10"
                    ><span>Submitted</span>&nbsp;<time
                      datetime="2021-05-20T04:23:14+00:00"
                      >{{ getLeaveDetails.applied_date }}</time
                    ></small
                  >
                  <p class="note__content mt-1">
                    {{ getLeaveDetails.note }}
                  </p>
                </div>

                <div
                  class="note mt-2"
                  v-if="getLeaveDetails.accept_reject_by_id"
                >
                  <div class="flex__row">
                    <div class="flex__row" style="min-width: 0px">
                      <div class="flex__col identifier__image">
                        <a href="/profile/15967">
                          <img :src="getLeaveDetails.audit_avatar" alt="" />
                        </a>
                      </div>
                      <div class="flex__col flex__col--wide o-hidden mx-3">
                        <span class="ellipsis identifier__primary text-14"
                          ><b class="font-weight-medium"
                            ><a href="/profile/15967"
                              >{{ getLeaveDetails.audit_full_name }}
                            </a></b
                          ></span
                        >

                        <small
                          class="ellipsis identifier__secondary text-10"
                          v-if="getLeaveDetails.rejected_date"
                        >
                          <span>Declined</span>&nbsp;<time
                            datetime="2021-05-21T06:23:19+00:00"
                            >21 May 2021 12:08</time
                          ></small
                        >

                        <small
                          class="ellipsis identifier__secondary text-10"
                          v-if="getLeaveDetails.accepted_date"
                        >
                          <span>Approved</span>&nbsp;<time
                            datetime="2021-05-21T06:23:19+00:00"
                            >21 May 2021 12:08</time
                          ></small
                        >
                        <p>{{ getLeaveDetails.rejected_reason }}</p>
                      </div>
                    </div>
                    <div class="svg-icon">
                      <svg
                        v-if="getLeaveDetails.accepted_date"
                        class="icon icon-sm icon-primary"
                        style="fill: #14bd83"
                        viewBox="0 0 24 24"
                      >
                        <g>
                          <path
                            d="M12 22C6.5 22 2 17.5 2 12S6.5 2 12 2s10 4.5 10 10-4.5 10-10 10zm0-18c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8z"
                          ></path>
                          <path
                            d="M11.2 16.9l-4.1-4 1.8-1.8 1.9 2L15 7.3l2 1.4z"
                          ></path>
                        </g>
                      </svg>
                      <svg
                        v-if="getLeaveDetails.rejected_date"
                        class="icon icon-sm"
                        style="fill: #ff3f20"
                        viewBox="0 0 24 24"
                      >
                        <g>
                          <path
                            class="close_circle_svg__st1"
                            d="M12 22C6.5 22 2 17.5 2 12S6.5 2 12 2s10 4.5 10 10-4.5 10-10 10zm0-18c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8z"
                          ></path>
                          <path
                            class="close_circle_svg__st1"
                            d="M16.7 8.7l-1.4-1.4-3.3 3.3-3.3-3.3-1.4 1.4 3.3 3.3-3.3 3.3 1.4 1.4 3.3-3.3 3.3 3.3 1.4-1.4-3.3-3.3z"
                          ></path>
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>

                <div class="col-xs-12 text-center" style="margin-top: 20px">
                  <router-link
                    :to="{
                      name: 'leave.employee.request.edit',
                      params: { id: getLeaveDetails.id },
                    }"
                    class="btn btn-primary"
                    v-if="getLeaveDetails.status < 1 && !cancelFlag"
                    >Edit request
                  </router-link>
                  <button
                    class="btn btn-danger"
                   
                    v-if="getLeaveDetails.status < 1 && !cancelFlag"
                    @click="cancelFlag=true"
                    style="margin-left: 10px"
                  
                  >
                    Cancel request
                  </button>
                   <div v-if="cancelFlag" style="padding:10px;border: 1px solid #EBEDF3;">
                       <h6>Would you like to cancel this leave request</h6>
                  <button class="btn btn-success mx-2"   @click="cancelRequest(getLeaveDetails)"  ref="kt_cancel_leave_request" > <i class="fa fa-check"></i> Yes</button>
                  <button class="btn btn-danger mx-2"   @click="cancelFlag=false" > <i class="fa fa-times"></i> No</button>
                   </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.flex__row {
  display: flex;
  align-items: center;
  min-width: 0;
}
.flex__col--flex {
  display: flex;
}

svg:not(:root) {
  overflow: hidden;
}
.icon-md {
  width: 24px;
  height: 24px;
}

.icon {
  vertical-align: middle;
  flex-shrink: 0;
  transition: 125ms ease-out;
  transition-property: fill, stroke;
}

.shrink-0 {
  flex-shrink: 0;
}
.flex__row--center {
  justify-content: center;
}

.panel {
  overflow: hidden;
  border: 1px solid #dcdcdc;
  background-color: #fff;
  border-radius: 5px;
}

.calendar-card--lg {
  width: 110px;
  height: 110px;
  display: inline-flex;
  flex-direction: column;
  .calendar-card__content {
    flex-grow: 1;
    position: relative;
  }
  .calendar-card__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .calendar-card__day-month {
    font-size: 36px;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    color: #333;
  }

  .calendar-card__day-month {
    font-size: 30px;
    line-height: 20px;
    font-weight: bold;
  }

  .calendar-card__date {
    font-size: 10px;
    margin-bottom: 5px;
  }

  .calendar-card__date {
    font-size: 10px;
    text-transform: uppercase;
    color: #747474;
  }
}
.calendar-card {
  text-align: center;
  color: #333;
}
.text-decoration-none {
  text-decoration: none !important;
}

.panel__header {
  border-bottom: 1px solid #dcdcdc;
}
.panel--highlighted {
  background-color: #f2f4f7;
}

p {
  margin-bottom: 0;
}
#whole {
  .card {
    .card-header {
      padding: 16px !important;
      display: inline-flex;
      .close {
        border-left: 1px solid #ccc;
        height: 58px;
        width: 58px;
        /* background: black; */
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        text-align: center;
        i {
          line-height: 58px;
          font-size: 25px;

          color: #ccc;
          &:hover {
            color: #000;
          }
        }
      }
    }
  }
}
#d1 {
  .card {
    .card-footer {
      background: #f2f4f7;
      text-align: center;
      padding: 10px;
    }
  }
  .note {
    margin-top: 20px;
    background-color: #f7f7f7;
    padding: 15px;
    border-radius: 5px;
    position: relative;

    img {
      padding: 5px;
      width: 36px;
      border-radius: 50px;
      border: 1px solid #ccc;
    }
    .svg-icon {
      color: red;
      position: absolute;
      right: 10px;
      top: 10px;
    }
    .identifier__secondary {
      color: #747474;
      display: block;
    }
  }
}
.leave-type {
  display: inline-flex;
  h5 {
    line-height: 28px;
    margin-top: 4px;
  }
  .icon-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 50px;
    color: white;
    margin-right: 10px;
    margin-top: 2px;

    i {
      line-height: 29px;
      color: #fff;
      font-size: 14px;
    }
  }
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import { mapGetters } from "vuex";
import {
  FETCH_EMPLOYEE_LEAVE_DETAILS,
  EDIT_LEAVE_REQUEST,
} from "@/core/services/store/actions.type";
export default {
  data() {
    return {
      cancelFlag:false,
      loader: false,
      leaveType: "all",
      formdata: {
        note: "",
        from: "",
        to: "",
        typeId: "",
        employee_id: "",
        id: 0,
        status: "3",
      },
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Leaves", route: "/leaves" },
      { title: "Leave Request" },
    ]);
    this.$store.dispatch(FETCH_EMPLOYEE_LEAVE_DETAILS, this.$route.params.id);
  },
  methods: {
    cancelRequest(data) {
  
      
      const formdata = this.formdata;
      formdata.id = data.id;
      formdata.note = data.note;
      formdata.from = data.from;
      formdata.to = data.to;
      formdata.typeId = data.leave_type_id;

      // s spinner to submit button
      const submitButton = this.$refs["kt_cancel_leave_request"];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store
        .dispatch(EDIT_LEAVE_REQUEST, formdata)
        // go to which page after successfully login
        .then((response) => {
          this.cancelFlag=false;
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;

          this.toast("success", "Success!", true, response.message);
          this.errors = [];
         this.$store.dispatch(FETCH_EMPLOYEE_LEAVE_DETAILS, this.$route.params.id);
        })
        .catch((errors) => {
          this.errors = errors.errors;
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.toast("danger", "Error!", true, errors.message);
        });
    },

    toast(variant = null, title, append = false, message) {
      this.$bvToast.toast(message, {
        title: title,
        toaster: "b-toaster-bottom-center",
        variant: variant,
        solid: true,
        appendToast: append,
      });
    },
  },

  computed: {
    ...mapGetters(["getLeaveDetails"]),
  },
  filters: {
    leaveStatus(status) {
      var str = "";
      switch (status) {
        case "0":
          str = "Pending";
          break;
        case "1":
          str = "Accepted";
          break;
        case "2":
          str = "Declined";
          break;
        case "3":
          str = "Canceled";
          break;
      }
      return str;
    },
    leaveStatusClass(status) {
      var class_ = "";
      switch (status) {
        case "0":
          class_ = "badge-info";
          break;
        case "1":
          class_ = "badge-success";
          break;
        case "2":
          class_ = "badge-danger";
          break;
        case "3":
          class_ = "badge-secondary";
          break;
      }
      return class_;
    },
  },
};
</script>
